@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./colors.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.divLogo {
  background: var(--secondary-color);
  border-radius: 14px;
}
.bordureAuth {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.divLogo p {
  color: var(--primaryColor);
}
.input-with-icon {
  padding: 20px 45px;
  border: none;
}
.containerInput {
  border-bottom: 1px solid var(--septanaryBorderColor) !important;
}
.cv-input-with-icon {
  box-shadow: none !important;
}
.colorIcon {
  color: var(--septanaryBorderColor) !important;
}
.input-group.auth-form-group {
  position: relative;
  margin-bottom: 23px;
}
.input-group.auth-form-group .input-group-prepend {
  position: absolute;
  z-index: 999;
  top: 20%;
  left: 1%;
  background: transparent;
}
.titreConnexion {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  color: var(--primarytextColor);
}
.containerAuth {
  background: var(--tertiareColore);
}
.mdpOublie {
  color: var(--secondary-color);
}
.auth-submit-btn {
  background: var(--secondary-color) !important;
  padding: 10px 50px !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}
.hoverInteresser:hover{
  background: white;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}
.css-13cymwt-control:hover, .css-13cymwt-control{
  border-color: grey !important;
}
.gedSfP {
  border: 1px dashed var(--secondary-color) !important;
  min-width: 100% !important;
  max-width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  background-color: var(--quaternary-color);
  height: 148px !important;
}
.llWxFx .file-types {
  display: none;
}
.react-datepicker__header {
  background-color: var(--primaryColor) !important;
  border-bottom: none !important;
  font-weight: 500 !important;
  font-size: 10.54px !important;
  font-family: Montserrat !important;
  color: #131A29 !important;
  padding: 12px;
}
.react-datepicker__day--selected{
  background-color: var(--secondary-color) !important;
}
.react-datepicker__day:hover{
  background-color: var(--secondaryClair-color) !important;
  border: 1px solid var(--secondary-color) !important;
}